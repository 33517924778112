.markdown-header {
  background-color: #f8a488;
  color: white;
  padding: 10px;
  border-radius: 8px 8px 0 0;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
}

.markdown-body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #fff7e6;
  padding: 20px;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #f8a488;
}

.markdown-body h1,
.markdown-body h2,
.markdown-body h3 {
  border-bottom: 1px solid #eaecef;
  padding-bottom: 0.3em;
  margin-bottom: 0.5em;
  color: #0366d6;
}

.markdown-body ul {
  list-style-type: disc;
  padding-left: 20px;
}

.markdown-body ol {
  list-style-type: decimal;
  padding-left: 20px;
}

.markdown-body li {
  margin-bottom: 0.5em;
}

.markdown-body p {
  margin-bottom: 1em;
}

.markdown-body strong {
  font-weight: bold;
  color: #d73a49;
}
